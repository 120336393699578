export const gateway = "https://sbx-belowcost-bms-api.link-nonprod.wawanesalife.com";
export const assets = "https://sbx-assets.link-nonprod.wawanesalife.com";
export const LoginUrlParameter = "https://sbx-brokers.auth.ca-central-1.amazoncognito.com/oauth2/authorize?client_id=2af7hqfts04mnhrf98v9qbicbu&response_type=code&scope=email+openid&redirect_uri=https%3A%2F%2Fsbx-belowcost-bms.link-nonprod.wawanesalife.com%2Fredirect";
export const BrokerPoolClientParameter = "2af7hqfts04mnhrf98v9qbicbu";
export const BrokerPoolDomainParameter = "sbx-brokers";
export const BrokerPoolParameter = "ca-central-1_6MtXxTkqE";

export const brokerBranding = {
	"sbx-belowcost-shopper.link-nonprod.wawanesalife.com": {
		a: {
			environment: "sbx",
			broker: "belowcost",
			website: "https://live.belowcost.net/",
			accessibilityLink: "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
			themeSwitcher: "show",
			ftcLink: "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
			privacyLink: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
			layout: "modern",
			scope: "belowcost#branding#sbx-belowcost-shopper.link-nonprod.wawanesalife.com#a",
			approve_company: "Link Platform",
			email: "bob@belowcost.lol",
			video_link: "SF1CFpZI1tY",
			g4: "G-2QM64W4BM4",
			photos: "carousel1.jpg,carousel2.jpg,carousel3.jpg,carousel4.jpg,carousel5.jpg,carousel6.jpg,carousel7.jpg,carousel8.jpg,carousel9.jpg,carousel10.jpg,carousel11.jpg",
			approve_user: "link_belowcost",
			logoPosition: "menu",
			environment_id: "sbx",
			brands: "sbx-belowcost-shopper.link-nonprod.wawanesalife.com",
			maps: "[object Object]",
			introduction: "null",
			phone: "(204) 555-4321",
			menuPhone: "button",
			ab: "a",
			logo: "a.png",
			brand_name: "sbx-belowcost-shopper.link-nonprod.wawanesalife.com",
		},
		b: {
			environment: "sbx",
			broker: "belowcost",
			website: "https://live.belowcost.net/",
			accessibilityLink: "https://en.wikipedia.org/wiki/Web_Content_Accessibility_Guidelines",
			themeSwitcher: "show",
			ftcLink: "https://www.fsrao.ca/regulation/guidance/fair-treatment-customers-insurance",
			privacyLink: "https://www.priv.gc.ca/en/privacy-topics/privacy-laws-in-canada/the-personal-information-protection-and-electronic-documents-act-pipeda/",
			layout: "modern",
			scope: "belowcost#branding#sbx-belowcost-shopper.link-nonprod.wawanesalife.com#b",
			approve_company: "Link Platform",
			email: "bob@belowcost.lol",
			video_link: "SF1CFpZI1tY",
			g4: "G-2QM64W4BM4",
			photos: "carousel1.jpg,carousel2.jpg,carousel3.jpg,carousel4.jpg,carousel5.jpg,carousel6.jpg,carousel7.jpg,carousel8.jpg,carousel9.jpg,carousel10.jpg,carousel11.jpg",
			approve_user: "link_belowcost",
			logoPosition: "menu",
			environment_id: "sbx",
			brands: "sbx-belowcost-shopper.link-nonprod.wawanesalife.com",
			maps: "[object Object]",
			introduction: "null",
			phone: "(204) 555-4321",
			menuPhone: "button",
			ab: "b",
			logo: "b.png",
			brand_name: "sbx-belowcost-shopper.link-nonprod.wawanesalife.com",
		},
	},
};

//updated on Thu Aug 15 2024 22:12:42 GMT+0000 (Coordinated Universal Time)
