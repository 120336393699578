import React, {
    useContext,
    useEffect,
    useState,
} from 'react';

import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';
import { MdOutlineCloudSync } from 'react-icons/md';
import {
    BrowserRouter,
    Route,
    Routes,
} from 'react-router-dom';

import { ActionCard } from '../controls/cards';
import { Dashboard } from '../dashboard/dashboard';
import { Downloads } from '../downloads/main';
import { Lead } from '../leads/lead';
import { Leads } from '../leads/leads';
import * as api from '../libs/api';
import * as contexts from '../libs/contexts';
import * as ui from '../libs/ui';
import { Reports } from '../reports/main';
import { GoogleAnalytics } from '../settings/analytics';
import { ApiKeys } from '../settings/apikeys';
import { Assets } from '../settings/assets';
import { Brands } from '../settings/brands';
import { SalesArguments } from '../settings/closing';
import { Contact } from '../settings/contact';
import { Fields } from '../settings/fields';
import { HappyPath } from '../settings/happypath';
import { Labels } from '../settings/labels';
import { Settings } from '../settings/main';
import { Profiles } from '../settings/profiles';
import { Urls } from '../settings/urls';
import { Users } from '../settings/users';
import { Video } from '../settings/video';
import {
    LoginPage,
    RedirectPage,
} from './login';
import {
    Page,
    SecurePage,
} from './page';

export const ControlRoom = ({ query, desktop }) => {

    return (
        <>
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={ <Page query={ query } desktop={ desktop } /> }>
                        <Route index element={ <Dashboard /> } />
                        <Route path="login" element={ <LoginPage /> } />
                        <Route path="redirect" element={ <RedirectPage /> } />
                        <Route path="redirect.html" element={ <RedirectPage /> } />
                        <Route path="leads" element={ <Leads /> } />
                        <Route path="leads/:id" element={ <Lead /> } />
                        <Route path="reports" element={ <Reports /> } />
                        <Route path="downloads" element={ <Downloads /> } />
                        <Route path="settings" element={ <Settings /> } />
                        <Route path="analytics" element={ <GoogleAnalytics /> } />
                        <Route path="apikeys" element={ <ApiKeys /> } />
                        <Route path="assets" element={ <Assets /> } />
                        <Route path="brands" element={ <Brands /> } />
                        <Route path="closing" element={ <SalesArguments /> } />
                        <Route path="contact" element={ <Contact /> } />
                        <Route path="fields" element={ <Fields /> } />
                        <Route path="happypath" element={ <HappyPath /> } />
                        <Route path="labels" element={ <Labels /> } />
                        <Route path="profiles" element={ <Profiles /> } />
                        <Route path="urls" element={ <Urls /> } />
                        <Route path="users" element={ <Users /> } />
                        <Route path="video" element={ <Video /> } />
                        <Route path="*" element={ <Dashboard /> } />
                    </Route>

                </Routes>
            </BrowserRouter>


        </>
    )

}

export const AppSession = ({ query, desktop }) => {

    const { session, setSession } = useContext(contexts.SessionContext);
    const { settings, setSettings } = useContext(contexts.SettingsContext);
    const { layout, setLayout } = useContext(contexts.LayoutContext);
    const { reportingData, setReportingData } = useContext(contexts.ReportingDataContext);
    const [hasRefreshed, setHasRefreshed] = useState(false)
    const [hasData, setHasData] = useState(false);

    useEffect(() => {

        ui.getLayout((data) => {
            data.desktop = desktop;
            data.query = query;
            setLayout(data);
        });


    }, []);

    useEffect(() => {

        ui.getLayout((data) => {
            data.desktop = desktop;
            data.query = query;
            setLayout(data);
        });

        // if (!hasRefreshed) {
        //     api.refreshReports({
        //         token: session.token, next: (data) => {
        //             console.info("Refresh done", data);
        //             setHasRefreshed(true);
        //         }, verbose: false
        //     });
        // }

    }, [hasRefreshed]);

    useEffect(() => {


        api.getSettings((data) => {
            console.info("Got session data", data);
            setSettings(data);
        });


        if (undefined !== session && session.email) {

            api.getData({
                token: session.token, next: (data) => {

                    setReportingData(data);
                    setHasData(true);

                }, verbose: true
            });

            api.getSettings((data) => {

                setSettings(data);
            });

        }

    }, [session]);

    return (
        <>  { hasData ?
            <SecurePage />
            :
            <WaitingScreen />
        }

        </>
    )

}

const WaitingScreen = () => {


    return (
        <Container>
            <Row className="mt-4">
                <Col className="col-md-6 offset-md-3">
                    <ActionCard
                        title="Just a moment"
                        text="We're gathering the latest metrics and insights..."
                        icon={ <MdOutlineCloudSync /> }
                    />
                </Col>
            </Row>
        </Container>

    );

}