const parseJwt = (token) => {
    let payload = {};
    if (token) {
        let parts = token.split('.');
        let base64Url = '';
        if (parts && parts.length > 1) {
            base64Url = parts[1];
        }
        if (base64Url) {
            let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            let content = decodeURIComponent(atob(base64).split('').map(function (c) {
                return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
            }).join(''));
            payload = JSON.parse(content);
        }
    }
    return payload;
};

export const fetchTokens = ({ code, redirect, client, endpoint, next }) => {

    if (code) {

        processCode({
            code: code,
            redirect: redirect,
            endpoint: endpoint,
            client: client,
            next: (tokens) => {

                let sessionInfo = {};
                for (let [k, v] of Object.entries(tokens)) {
                    localStorage.setItem(k, v);
                    if (["id_token", "access_token"].includes(k)) {
                        let decoded = parseJwt(v);
                        sessionInfo = { ...sessionInfo, ...decoded };
                        sessionInfo[k] = v;
                    }
                }

                sessionInfo.expires = friendlyDate(sessionInfo.exp);
                sessionInfo.issued = friendlyDate(sessionInfo.iat);
                sessionInfo.authenticated_time = friendlyDate(sessionInfo.auth_time);
                localStorage.setItem("session", JSON.stringify(sessionInfo));

                next(sessionInfo);

            }
        });


    } else {
        //onsole.error("No authorization code");
    }
}

export const processCode = ({ code, endpoint, redirect, client, next }) => {

    let tokens = {};

    if (code) {

        // we need to trade our code for tokens
        let requestData = {
            grant_type: "authorization_code",
            code: code,
            redirect_uri: redirect,
            client_id: client
        };

        // we prepare the payload
        let formBody = [];
        for (let property in requestData) {
            let encodedKey = encodeURIComponent(property);
            let encodedValue = encodeURIComponent(requestData[property]);
            formBody.push(encodedKey + "=" + encodedValue);
        }
        formBody = formBody.join("&");
        const params = {
            method: 'POST',
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: formBody
        };

        // we're sending the request to cognito
        let url = endpoint + '/oauth2/token';

        fetch(url, params)
            .then((response) => {
                return response.json();
            })
            .then((responseData) => {

                for (let token of ['id_token', 'access_token', 'refresh_token']) {
                    let tokenValue = responseData[token];
                    tokens[token] = tokenValue;
                }
                next(tokens);
            })

    }

};


const friendlyDate = (unfriendly) => {

    let friendly = '';

    if (!isNaN(unfriendly)) {
        let seconds = parseInt(unfriendly);
        let date = new Date(1000 * seconds);
        friendly = date.toLocaleTimeString();
    }
    return friendly;
};


export const getSession = (next) => {
    let session = {
        email: 'jlmartel@wawanesa.com'
    };
    if (next) {
        next(session);
    }
}