import React, {
  useContext,
  useMemo,
} from 'react';

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  BsFileText,
  BsFiletypeCsv,
} from 'react-icons/bs';

import * as config from '../config.js';
import { ActionCardGroup } from '../controls/cards';
import { Hero } from '../controls/hero';
import {
  LayoutContext,
  LeadsContext,
  ReportingDataContext,
} from '../libs/contexts';

const TemporaryDisabled = () => {

    return (
        <>
            <div className="text-center">
                <Badge>not available in demo</Badge>
            </div>
        </>
    )
}

export const Downloads = () => {

    const { layout } = useContext(LayoutContext);
    const { leads } = useContext(LeadsContext);
    const { reportingData } = useContext(ReportingDataContext);

    // this is where we compose the screen
    const cards = useMemo(() => {

        let callbacks = {};
        let fullUrl = config.gateway + "/download/leads.csv?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiJibXMiLCJuYW1lIjoibGluayIsImlhdCI6MTcwODM5NDAzMn0.zBK1Y-njzNEsFHMi4obZ8IRPWY9Zk8Uz24c2s5eGmCA";

        // this object eventually contains download URLs and should be populated at the context level
        if (undefined === leads.downloads) {
            callbacks.leads = fullUrl;
            callbacks.events = false;
        } else {
            callbacks.events = undefined === leads.downloads.events ? false : leads.downloads.events;
            callbacks.leads = undefined === fullUrl;
        }

        return {
            leads: {
                title: "All leads",
                text: "Download the entire list of visitors who provided contact information.",
                icon: <BsFiletypeCsv />,
                label: "Download",
                callback: fullUrl
            }
        };
    }, [layout.scheme, leads.downloads]);

    return (
        <>
            <Hero
                title="Downloads"
                text="Data sets available on this page are in CSV format and always up to date."
            />
            <ActionCardGroup groupDefinition={cards} />
        </>
    );
}


