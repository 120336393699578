


export const HappyPath = () => {

    return (
        <>Happy Path</>
    );
}

