import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  BiImageAdd,
  BiLinkExternal,
  BiSupport,
  BiUserCheck,
} from 'react-icons/bi';
import {
  BsCardText,
  BsChatHeart,
  BsCheckSquare,
  BsGoogle,
  BsKey,
  BsMegaphone,
  BsSortDown,
  BsYoutube,
} from 'react-icons/bs';
import { TbArrowFork } from 'react-icons/tb';

import * as config from '../config.js';
import { ActionCardGroup } from '../controls/cards';
import { Hero } from '../controls/hero';
import {
  LayoutContext,
  ReportingDataContext,
  SettingsContext,
} from '../libs/contexts';
import {
  BasicViewer,
  BrandingViewer,
} from './config-viewer';

export const Settings = () => {

    const { layout } = useContext(LayoutContext);
    const { settings, setSettings } = useContext(SettingsContext);

    const { reportingData } = useContext(ReportingDataContext);
    const [systemInfo, setSystemInfo] = useState(false);



    useEffect(() => {
        if (reportingData) {

            let newSystemInfo = {};

            if (reportingData.customization) {
                newSystemInfo.api_key = reportingData.customization.api_key;
                newSystemInfo.subscriptions = reportingData.customization.subscriptions;
            }

            if (config.brokerBranding) {
                //todo refactor

                newSystemInfo.branding = {};
                for (let [brand, profile] of Object.entries(config.brokerBranding)) {
                    newSystemInfo.branding[brand] = [];
                    for (let [ab, item] of Object.entries(profile)) {
                        let infoItem = {};
                        infoItem.profile = ab;
                        infoItem.id = item.broker;
                        infoItem.brand = brand;

                        newSystemInfo.branding[brand][ab] = {};
                        newSystemInfo.branding[brand][ab].id = item.broker;
                        let optionals = ['accessibilityLink', 'privacyLink', 'ftcLink', 'tcLink', 'email', 'phone', 'website', 'map1', 'map2', 'map3', 'map4', 'photo1', 'photo2', 'photo3', 'photo4', 'video1', 'video2', 'video3', 'video4'];

                        for (let optional of optionals) {
                            if (item[optional]) {
                                if ("hide" !== item[optional]) {
                                    infoItem[optional] = item[optional];
                                }
                            }
                        }

                        if (item.g4) {
                            infoItem.google = item.g4;
                        }

                        newSystemInfo.branding[brand].push(infoItem);

                    }
                }

            }

            setSystemInfo(newSystemInfo);
        }

    }, [reportingData]);

    return (
        <>
            {systemInfo &&
                <>
                    <Hero title="System settings" />
                    <BasicViewer systemInfo={systemInfo} />
                    <Hero title="Branding" />
                    <BrandingViewer branding={systemInfo.branding} />
                </>
            }
        </>
    );
}



