import 'react-dropzone-uploader/dist/styles.css';

import React, {
  useContext,
  useEffect,
  useState,
} from 'react';

import {
  Button,
  Card,
  Col,
  Container,
  Form,
  Image,
  Row,
} from 'react-bootstrap';
import Dropzone from 'react-dropzone-uploader';

export const Assets = () => {

    return (
        <><LogoUpload /></>
    );
}

const LogoUpload = () => {

    return (<>

    </>);

}



