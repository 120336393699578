import * as config from '../config.js';

export const getRandomMissingPhoto = () => {

    let index = 1 + Math.floor(Math.random() * 4);

    let url = config.assets + "/stock/notfound-" + index.toString() + ".jpg";

    return url;
}

export const getRandomProvincePhoto = ({ province }) => {

    let index = 1 + Math.floor(Math.random() * 4);
    let region = 'east';

    if (['AB', 'BC', 'YT', 'NT'].includes(province)) {
        region = 'west';
    }

    if (['SK', 'MB'].includes(province)) {
        region = 'central';
    }

    if (['PE', 'NB', 'NS', 'NL'].includes(province)) {
        region = 'maritimes';
    }

    let url = config.assets + "/stock/provinces/" + region + index.toString() + ".jpg";

    return url;
}