import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';
import { useParams } from 'react-router-dom';

import { QuoterCard } from '../app/quoters';
import { InfoCard } from '../controls/cards';
import { SimpleGrid } from '../controls/grids';
import { Hero } from '../controls/hero';
import { SettingsContext } from '../libs/contexts';

export const Video = () => {

  const { settings, setSettings } = useContext(SettingsContext);

  const onChange = ({ item, action }) => {
    //onsole.info("Change", action, item);
  }


  return (
    <>
      <Hero title="Introduction video" text="YouTube video to welcome visitors" />
      <Container fluid>
        <Row className="mb-4">
          <Col className="col-6">
            {settings && settings.video &&
              <SimpleGrid allowDelete={false} allowInsert={false} rows={settings.video} onChange={onChange} showId={true} />
            }
          </Col>
        </Row>
      </Container>
    </>
  );
}
