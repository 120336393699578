import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import {
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { AgGridReact } from 'ag-grid-react';
import {
  Alert,
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  ListGroup,
  Row,
  Tab,
  Tabs,
} from 'react-bootstrap';
import {
  BiChurch,
  BiSolidCreditCardAlt,
  BiSolidPhoneCall,
} from 'react-icons/bi';
import {
  BsArrowLeftRight,
  BsCaretRight,
  BsCartPlus,
  BsEnvelopeAt,
  BsHouseHeart,
} from 'react-icons/bs';
import {
  FaBabyCarriage,
  FaBalanceScale,
  FaCar,
  FaHandsHelping,
} from 'react-icons/fa';
import {
  MdRefresh,
  MdSchool,
} from 'react-icons/md';

import { SimpleGrid } from '../controls/grids';
import { Hero } from '../controls/hero';
import { SettingsContext } from '../libs/contexts';

export const Users = () => {

    return (
        <>
            <Subscriptions />
            <SystemUsers />
        </>
    );
}



const Subscriptions = () => {

    const { settings, setSettings } = useContext(SettingsContext);

    const onChange = ({ item, action }) => {
        //onsole.info("Change", action, item);
    }

    return (
        <>
            <Hero title="Notifications" text="List of subscribers to new lead notifications and weekly reports" />
            <Container fluid>
                <Row className="mb-4">
                    <Col className="col-6">

                        {settings && settings.users && settings.users.notifications &&
                            <SimpleGrid allowInsert={true} allowDelete={true} rows={settings.users.notifications} onChange={onChange} />
                        }
                        <p className="text-center text-muted">The verification code sent by email must be confirmed to enable a subcription</p>
                    </Col>
                </Row>
            </Container>
        </>
    )

}

const SystemUsers = () => {


    const { settings, setSettings } = useContext(SettingsContext);

    const onChange = ({ item, action }) => {
        //onsole.info("Change", action, item);
    }

    return (
        <>
            <Hero title="System users" text="Please contact us if you need additional users" />
            <Container fluid>
                <Row className="mb-4">
                    <Col className="col-6">
                        {settings && settings.users && settings.users.system &&
                            <SimpleGrid readOnly={true} rows={settings.users.system} onChange={onChange} />
                        }
                    </Col>

                </Row>
            </Container>
        </>
    )

}