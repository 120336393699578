import {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Card,
  Col,
  Container,
  Image,
  Row,
} from 'react-bootstrap';
import { FiPhoneCall } from 'react-icons/fi';
import { MdOutlineMarkEmailRead } from 'react-icons/md';
import { RiMailSendLine } from 'react-icons/ri';

import {
  getRandomMissingPhoto,
  getRandomProvincePhoto,
} from '../controls/photos';
import { asHyperlink } from '../libs/contact';

export const CachedLead = ({ quoteId, lead }) => {


    const photo = useMemo(
        () => {

            if (false !== lead) {
                if (Object.keys(lead).length > 1) {
                    if (lead.province_current) {
                        let province;
                        province = lead.province_current;
                        let photoUrl = getRandomProvincePhoto({ province: province });
                        console.info("Got photo url", photoUrl);
                        return photoUrl;
                    }
                } else {
                    let photoUrl = getRandomMissingPhoto();
                    return photoUrl;
                }

            }
        }, [lead, lead.province_current]
    );


    return (
        <>
            {false !== lead ?
                <Container className="my-5">
                    <Row className="pb-0 pe-lg-0 align-items-center rounded-3 border shadow-lg">
                        {lead &&
                            <Col className="col-lg-7 p-3 p-lg-5 pt-lg-3">

                                {lead.date ?
                                    <>
                                        <LeadTitle lead={lead} />
                                        <LeadHeader lead={lead} />
                                        <ContactCard contact={lead} />
                                    </>
                                    :
                                    <NotFound />
                                }


                            </Col>
                        }
                        {photo &&
                            <Col className="col-lg-4 offset-lg-1 p-0 overflow-hidden shadow-lg">
                                <Image className="rounded-lg-3" src={photo} alt="" width="720" />
                            </Col>
                        }

                    </Row>

                    <Row>
                        <Col className="text-center col-12 mt-4 mb-4">
                            <a href="/">View all leads</a>
                        </Col>
                    </Row>

                </Container>
                :
                <></>
            }
        </>
    )
}

const ContactCard = ({ contact }) => {

    const [phone, setPhone] = useState(false);
    const [email, setEmail] = useState(false);

    useEffect(() => {
        if (contact.phone) {
            let newPhone = asHyperlink({ contactInfo: contact.phone });
            setPhone(newPhone);
        }
        if (contact.email) {
            let newEmail = asHyperlink({ contactInfo: contact.email });
            setEmail(newEmail);
        }
    }, [contact.phone, contact.email]);

    return (<>

        <Card>

            {(contact.first_name || contact.last_name) &&
                <Card.Header>
                    {contact.first_name} {contact.last_name}
                </Card.Header>
            }

            <Card.Body>
                <Container fluid>
                    <Row>
                        {phone &&

                            <Col>
                                <h1><a className="action-click" href={phone.href}><FiPhoneCall /></a></h1>
                                <Badge>{phone.text}</Badge>
                            </Col>

                        }


                        {email &&
                            <Col>
                                <h1><a className="action-click" href={email.href}><RiMailSendLine /></a></h1>
                                <Badge>{email.text}</Badge>
                            </Col>
                        }

                    </Row>
                </Container>


            </Card.Body>


        </Card>

    </>);


}


const LeadHeader = ({ lead }) => {

    return (
        <p className="lead mt-3 mb-4">
            {lead.age} {lead.gender} {lead.city ? ` from ${lead.city}` : ''}
        </p>
    );

}

const LeadTitle = ({ lead }) => {

    return (<>
        {lead.date &&
            <h1 className="display-4 fw-bold lh-1 text-body-emphasis">
                Someone {lead.province ? "in " + lead.province : ""} needs a {lead.premium_coverage ? lead.premium_coverage + " " : " "} policy.
            </h1>
        }
    </>);

}


const NotFound = () => {


    return (
        <>
            <h1 className="display-4 fw-bold lh-1 text-body-emphasis">
                The information you are looking for is not here.
            </h1>
            <p className="lead mt-4 mb-4">
                If you clicked on a link in a lead notification, it has expired. You can still access all your leads in your <a href="/">Control Room</a>.
            </p>
        </>
    );

}