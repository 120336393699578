import React, {
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

import {
  Badge,
  Button,
  Card,
  CardGroup,
  Col,
  Container,
  Form,
  Image,
  ListGroup,
  Nav,
  Navbar,
  NavDropdown,
  Row,
} from 'react-bootstrap';
import {
  BiImageAdd,
  BiLinkExternal,
  BiSupport,
  BiUserCheck,
} from 'react-icons/bi';
import {
  BsCardText,
  BsChatHeart,
  BsCheckSquare,
  BsGoogle,
  BsKey,
  BsMegaphone,
  BsSortDown,
  BsYoutube,
} from 'react-icons/bs';
import { TbArrowFork } from 'react-icons/tb';

import { ActionCardGroup } from '../controls/cards';
import { ReadOnlyGrid } from '../controls/grids';
import { Hero } from '../controls/hero';
import {
  LayoutContext,
  ReportingDataContext,
  SettingsContext,
} from '../libs/contexts';

export const BasicViewer = ({ systemInfo }) => {

    const [viewItems, setViewItems] = useState(false);

    useEffect(() => {
        if (systemInfo) {

            let newItems = {
                integrations: {
                    "API Key": systemInfo.api_key
                },
                subscriptions: systemInfo.subscriptions
            };

            setViewItems(newItems);

        }
    }, [systemInfo]);


    return (
        <>
            <Container>
                {viewItems && viewItems.integrations &&
                    <Row className="mb-4">
                        <Col>
                            <Card>
                                <Card.Header>Integrations</Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        <ListGroup.Item>API Key: {viewItems.integrations['API Key']}</ListGroup.Item>
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }

                {viewItems && viewItems.subscriptions &&
                    <Row className="mb-4">
                        <Col>
                            <Card>
                                <Card.Header>Subscriptions</Card.Header>
                                <Card.Body>
                                    <ListGroup variant="flush">
                                        {viewItems.subscriptions.map((subscription) => (
                                            <ListGroup.Item key={subscription}>{subscription}</ListGroup.Item>
                                        ))}
                                    </ListGroup>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                }
            </Container>
        </>
    )

}


export const BrandingViewer = ({ branding }) => {


    return (

        <>
            {branding &&
                <Container>
                    {Object.entries(branding).map(([brand, ab], index) => (
                        <Row key={brand}>
                            <Col>
                                <h5>Brand: {brand}</h5>
                                <Container fluid>
                                    <Row className="mt-4 mb-4">
                                        <Col>
                                            <ReadOnlyGrid height={140} rows={ab} />
                                        </Col>
                                    </Row>
                                </Container>
                            </Col>
                        </Row>
                    ))}
                </Container>
            }

        </>
    )

}

const BrandingTable = ({ }) => {



}