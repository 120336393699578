import React, {
    useContext,
    useEffect,
    useReducer,
    useState,
} from 'react';

import { init } from 'echarts';
import {
    Button,
    Card,
    Col,
    Container,
    Image,
    ListGroup,
    Nav,
    Navbar,
    NavDropdown,
    Row,
} from 'react-bootstrap';
import {
    BsBarChartLine,
    BsCartCheck,
    BsCloudDownload,
    BsFiletypeCsv,
    BsGearFill,
    BsStar,
} from 'react-icons/bs';

import { Hero } from '../controls/hero';
import {
    LayoutContext,
    SettingsContext,
} from '../libs/contexts';

export const Brands = () => {

    const { layout } = useContext(LayoutContext);
    const { settings } = useContext(SettingsContext);

    return (
        <Container fluid className="mt-4">
            <Row>
                <Col>
                    <Hero text="Brands allow you to customize settings based on the origin hostname." title="Brands" />
                </Col>
            </Row>

            <Row>

                {settings.brands ?
                    <>
                        {settings.brands.map((brand, index) => (
                            <Col className='col-md-6 col-lg-4 col-xl-3' key={index}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Brand #{index + 1}</Card.Title>
                                        <Card.Text>
                                            {brand}
                                        </Card.Text>
                                    </Card.Body>
                                    <Card.Footer>
                                        To remove this brand, please contact us.
                                    </Card.Footer>
                                </Card>
                            </Col>
                        ))}
                        <Col className='col-md-6 col-lg-4 col-xl-3'>
                            <Card className="bg-light">
                                <Card.Body>
                                    <Card.Title>New brand?</Card.Title>
                                    <Card.Text>
                                        To add a new brand, please contact us.
                                    </Card.Text>
                                </Card.Body>
                                <Card.Footer>
                                    &nbsp;
                                </Card.Footer>
                            </Card>
                        </Col>
                    </>
                    :
                    <Col>no brands defined yet</Col>
                }
            </Row>
        </Container>

    );
}

