import { useContext } from 'react';

import {
    Col,
    Container,
    Row,
} from 'react-bootstrap';

import { EventsCard } from '../app/events';
import { QuoterCard } from '../app/quoters';
import { FeaturedLeadsCard } from '../leads/featured';
import { LayoutContext } from '../libs/contexts';
import { HistoryChartCard } from '../reports/history';
import { BasicInsightsCard } from '../reports/insights';
import { LinkAdvisorCard } from '../settings/advisor';

export const Dashboard = () => {

    const { layout } = useContext(LayoutContext);

    return (<>
        {layout.desktop ?
            <DesktopDashboard />
            :
            <MobileDashboard />
        }

    </>);
}

const DesktopDashboard = () => {

    return (
        <Container fluid>
            <Row className="mt-4">
                <Col className="col-md-12 col-lg-6">

                    <Container fluid>
                        <Row>
                            <Col>
                                <FeaturedLeadsCard footer='full' />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            <Col>

                                <BasicInsightsCard footer='full' />

                            </Col>
                            {/* <Col>
                                <Col>
                                    <EventsCard footer='full' />
                                </Col>
                            </Col> */}
                        </Row>
                    </Container>

                </Col>

                <Col className="col-md-12 col-lg-6">
                    <Container fluid>
                        <Row>
                            <Col>
                                <HistoryChartCard footer='full' />
                            </Col>
                        </Row>
                        <Row className="mt-4">
                            {/* <Col>
                                <BasicInsightsCard footer='full' />
                            </Col> */}
                            {/* <Col>
                                <LinkAdvisorCard footer='full' />
                            </Col> */}
                        </Row>
                    </Container>

                </Col>

            </Row>

        </Container>
    );

}

const MobileDashboard = () => {

    return (
        <Container fluid>
            <Row className="mt-4">
                <Col className="col-12 col-lg-6 mb-2">
                    <FeaturedLeadsCard footer='minimal' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <HistoryChartCard footer='none' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <QuoterCard footer='none' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <EventsCard footer='minimal' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <BasicInsightsCard footer='none' />
                </Col>
                <Col className="col-12 col-lg-6 mb-2">
                    <LinkAdvisorCard footer='none' />
                </Col>
            </Row>

        </Container>
    );

}